import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "src/services/LocalStorageService";
const Layout = ({ children }) => {
  const navigate = useNavigate();

  const logOut = () => {
    LocalStorageService.deleteElement("token");
    navigate("/");
  };

  return (
    <div className="h-full">
      {/* Navbar */}
      <div className="flex align-items-center h-4rem bg-indigo-500 fixed z-3 w-full text-white">
        <div className="md:pl-6 lg:pl-8 sm:pl-4 pl-4 flex-1 uppercase">
          <h1>Atlante</h1>
        </div>
        <div className="flex-1 flex justify-content-end">
          <Button
            onClick={logOut}
            label="Esci "
            className="p-button-text text-white"
            icon="pi pi-sign-out"
            iconPos="right"
          />
        </div>
      </div>
      {/* Container */}
      <div>{children}</div>
    </div>
  );
};

export default Layout;
