import React from 'react'

function CardItem({title, value}) {
  return (
    <div className="flex justify-content-between py-2">
          <div className="text-500 font-medium">{title}</div>
          <div className="text-800">
            {value} 
        </div>
    </div>
  )
}

export default CardItem