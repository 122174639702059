import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

function Filters() {
  const [name, setName] = useState("");
  const [date, setDate] = useState(null);
  let maxDate = new Date();
  addLocale("it", {
    firstDayOfWeek: 1,
    dayNames: [
      "Domenica",
      "Lunedì",
      "Martedì",
      "Mercoledì",
      "Giovedì",
      "Venerdì",
      "Sabato",
    ],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    dayNamesMin: ["D", "L", "M", "M ", "G", "V ", "S"],
    monthNames: [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ],
    monthNamesShort: [
      "Gen",
      "Feb",
      "Mar",
      "Apr",
      "Mag",
      "Giu",
      "Lug",
      "Ago",
      "Set",
      "Ott",
      "Nov",
      "Dic",
    ],
    today: "Oggi",
  });

  const filterData = () => {};

  const clearFilter = () => {
    setName("");
    setDate(null);
  };

  return (
    <div className="grid flex align-items-center lg:py-4 md:py-4 sm:py-4 py-2">
      <div className="lg:col-4 md:col-4 sm:col-12 col-12">
        <InputText
          className="w-full"
          placeholder="Cerca per nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="lg:col-4 md:col-4 sm:col-12 col-12">
        <Calendar
          locale="it"
          className="w-full"
          maxDate={maxDate}
          placeholder="Cerca per data"
          selectionMode="range"
          showTime
          hourFormat="24"
          value={date}
          onChange={(e) => setDate(e.value)}
        ></Calendar>
      </div>
      <div className="lg:col-4 md:col-4 sm:col-12 col-12 pl-2 flex sm:justify-content-start justify-content-end">
        <Button onClick={filterData} label="Filter" icon="pi pi-filter" />
        <Button
          onClick={clearFilter}
          label="Clear"
          icon="pi pi-eraser"
          className="ml-2"
        />
      </div>
    </div>
  );
}

export default Filters;