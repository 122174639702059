import React, { useContext, createContext }  from "react";
import AuthAPI from "src/services/AuthAPI";
import LocalStorageService from "src/services/LocalStorageService";

const authContext = createContext();

const useAuthProvider = () => {

    const login = async (data) => {
        try {
            const response = await AuthAPI.login(data);
            
            LocalStorageService.setElement("token", true);
            return Promise.resolve();

        } catch(e) {
            return Promise.reject(e)
        }
    }

    const logout = () => {
        LocalStorageService.deleteElement("token");
    }

    return {
        login,
        logout
    }
}

export const AuthProvider = ({children}) => {
    const auth = useAuthProvider();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => useContext(authContext)