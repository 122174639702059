import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "src/providers/toast.provider";
import Layout from "src/layout/Layout";
import Container from "src/components/Container";
import MeasurementCardDetails from "src/components/MeasurementCardDetails";
import { Button } from "primereact/button";
import LineChart from "src/components/LineChart";
import MeasurementsAPI from "src/services/MeasurementsAPI";
// import data from "src/mockData/measurements"

const MeasurementDetails = () => {
  const [measurement, setMeasurement] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [graphPairs, setGraphPairs] = useState([]);
  const [isWelch, setIsWelch] = useState(true);
  const [lineStylesData, setLineStylesData] = useState({});
  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    getMeasurement();
  }, []);

  const getMeasurement = async () => {
    try {
      setIsLoading(true);
      const response = await MeasurementsAPI.getMeasurement(
        params.measurementId
      );
      setMeasurement(response)
      console.log(response);
    } catch (error) {
      toast.showError("Errore!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(measurement).length !== 0) {
      const [xArray, yArray] = (isWelch) ? [measurement.welch.x, measurement.welch.y] : [measurement.movement.x, measurement.movement.y]

      const resultArray = xArray.map((xElement, index) =>
      ({
        x: xElement,
        y: yArray[index],
        xy: [xElement, " , ", yArray[index]],

      }
      )
      );
      setGraphPairs(resultArray);

      // RELOCATE THE BELOW CODE ONTO TRY BLOCK
      setLineStylesData(
        {
          labels: xArray,
          datasets: [
            {
              label: `${measurement.nome} - ${isWelch ? 'Welch' : 'movement'}`,
              data: yArray,
              fill: true,
              tension: 0.4,
              borderColor: "#6366f1"
            }
          ],
        }
      );
    }
  }, [measurement, isWelch])


  return (
    <Layout>
      <Container>
        <div className="grid flex">
          <div className="lg:col-3 md:col-4 col-12 md:flex-order-0 lg:flex-order-0 flex-order-1">
            <Button
              onClick={() => navigate(`/dashboard`)}
              label="Indietro "
              className="p-button-text"
              icon="pi pi-arrow-left" />
          </div>
          <div className="lg:col-9 md:col-8 col-12 md:flex-order-1 lg:flex-order-1 flex-order-0">
            <Button
              onClick={() => setIsWelch(!isWelch)}
              label={`Change Graph to ${(!isWelch) ? "Welch": "Movement"}`}
              className="p-button-text"
              icon="pi pi-sync" />
          </div>
        </div>
        <div className="grid flex">
          <div className="lg:col-3 md:col-4 col-12 md:flex-order-0 lg:flex-order-0 flex-order-1">
            <MeasurementCardDetails measurement={measurement} />
          </div>
          <div className="lg:col-9 md:col-8 col-12 md:flex-order-1 lg:flex-order-1 flex-order-0">
            <LineChart lineStylesData={lineStylesData} graphPairs={graphPairs} />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default MeasurementDetails;