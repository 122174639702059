import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import CardItem from "./CardItem";

function MeasurementCardDetails({ measurement }) {
  const subtitle = (
    <div className="flex align-items-center justify-content-between text-sm">
      <div>
        <i className="pi pi-map-marker pr-1" />
        {measurement?.luogo}
      </div>
      <div>
        <i className="pi pi-calendar pr-1" />
        {(new Date(measurement.data)).toLocaleString()}
      </div>
    </div>
  );

  return (
    <Card title={measurement?.nome} subTitle={subtitle}>
      <div>
        <div className="flex">
          <div className="text-600 w-full text-sm">{measurement.desc}</div>
        </div>
        <Divider />
        {/* <CardItem
          title="Risoluzione"
          value={`(${measurement?.configCamera?.resolution?.x}, ${measurement?.configCamera?.resolution?.y})`}
        /> */}
        {/* <CardItem
          title="Frequenza"
          value={`Min: ${measurement?.configCamera?.frequency_window?.low}, Max: ${measurement?.configCamera?.frequency_window?.high}`}
        /> */}
        <CardItem
          title="Angoli"
          value={`Verticale: ${measurement?.configCamera?.angles?.vertical},`}
        />
        <CardItem
          title=""
          value={`Orizzontale: ${measurement?.configCamera?.angles?.horizontal}`}
        />
        {/* <CardItem
          title="Passo Pixel"
          value={measurement?.configCamera?.pixel_pitch}
        /> */}
        {/* <CardItem
          title="Frequenza dei fotogrammi"
          value={measurement?.configCamera?.frame_rate}
        /> */}
        {/* <CardItem title="Lambda" value={measurement?.configCamera?.lambda} /> */}
        <CardItem title="Potenza" value={measurement?.configCamera?.power} />
        <CardItem title="Temperatura" value={measurement?.configCamera?.temperature} />
        <CardItem title="Distanza" value={measurement?.configCamera?.distance} />
        {/* <CardItem
          title="Specchio"
          value={
            measurement?.configCamera?.mirror ? (
              <i className="pi pi-check-circle" style={{ color: "green" }}></i>
            ) : (
              <i className="pi pi-times-circle" style={{ color: "red" }}></i>
            )
          }
        /> */}
        {/* <CardItem
          title="Lambda"
          value={
            measurement?.configCamera?.focus ? (
              <i className="pi pi-check-circle" style={{ color: "green" }}></i>
            ) : (
              <i className="pi pi-times-circle" style={{ color: "red" }}></i>
            )
          }
        />*/}
      </div> 
    </Card>
  );
}

export default MeasurementCardDetails;
