import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'src/providers/auth.provider';
import LocalStorageService from "src/services/LocalStorageService";

const PrivateRoute = () => {
  const auth = useAuth();
  const hasToken = LocalStorageService.getElement('token')

    return (
      <div> 
        { hasToken ? <Outlet/> : <Navigate to='/'/>}
      </div>
  )
}

export default PrivateRoute