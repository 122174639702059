import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { useToast } from "src/providers/toast.provider";
import { Skeleton } from "primereact/skeleton";
import Layout from "src/layout/Layout";
import Container from "src/components/Container";
import Filters from "src/components/Filters";
import measurementsData from "src/mockData/measurements";
import MeasurementsAPI from "src/services/MeasurementsAPI";
import { DataScroller } from 'primereact/datascroller';

const Dashboard = () => {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getMeasurements();
  }, []);

  const getMeasurements = async () => {
    try {
      setIsLoading(true);
      const response = await MeasurementsAPI.getMeasurements();
      setPosts(response['results']);
    } catch (error) {
      toast.showError("Errore");
    } finally {
      // setPosts(measurementsData);
      setIsLoading(false);
    }
  };

  const customBodyTemplate = (rowData, propName) => (
    <>{isLoading ? <Skeleton></Skeleton> : (propName === 'data') ? (new Date(rowData[propName])).toLocaleString() : rowData[propName]}</>
  );

  const descriptionBodyTemplate = (rowData) => ( 
    <>
      {isLoading ? (
        <Skeleton></Skeleton>
      ) : (
        <>
          <Tooltip
            target=".desc"
            content={rowData.desc}
            mouseTrack
            mouseTrackLeft={10}
            position="bottom"
            style={{ width: "300px" }}
          />
          <div className="desc w-20rem white-space-nowrap overflow-hidden text-overflow-ellipsis">
            {rowData.desc}
          </div>
        </>
      )}
    </>
  );

  const actionBodyTemplate = () => (
    <>
      {isLoading ? (
        <Skeleton></Skeleton>
      ) : (
        <>
          <div className="flex justify-content-center align-items-center">
            <i className="pi pi-arrow-right"></i>
          </div>
        </>
      )}
    </>
  );

  const itemTemplate = (item) => {
    // custom item content
    <div>
      Hi
    </div>
}

  return (
    <Layout>
      <Container>
        <Filters />
        <DataScroller value={posts} itemTemplate={itemTemplate} rows={10}></DataScroller>
        <DataTable
          onRowSelect={(rowData) =>
            !isLoading && navigate(`/dashboard/${rowData.data.id}`)
          }
          selectionMode="single"
          value={posts}
          size="normal"
          responsiveLayout="scroll"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          dataKey="id"
          paginator
          emptyMessage="Nessun dato trovato"
          className="datatable-responsive border-solid border-1 surface-border"
          currentPageReportTemplate="Visualizzazione dal {first} al {last} di {totalRecords} risultati"
          rows={10}
        >
          {/* Name */}
          <Column
            header="NOME"
            body={(rowData) => customBodyTemplate(rowData, "nome")}
          ></Column>
          {/* Location */}
          <Column
            header="LUOGO"
            body={(rowData) => customBodyTemplate(rowData, "luogo")}
          ></Column>
          {/* Date */}
          <Column
            header="DATA"
            dataType="date"
            body={(rowData) => customBodyTemplate(rowData, "data")}
          ></Column>
          {/* Description */}
          <Column
            style={{ width: "30%" }}
            resizeable={false}
            header="DESCRIPTION"
            body={descriptionBodyTemplate}
          ></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </Container>
    </Layout>
  );
};

export default Dashboard;