import React, {useRef, useContext, createContext } from "react";
import { Toast } from "primereact/toast";

const toastContext = createContext();

const useToastProvider = (toast) => {
  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success Message",
      detail: message,
      life: 3000,
    });
  };

  const showInfo = (message) => {
    toast.current.show({
      severity: "info",
      summary: "Info Message",
      detail: message,
      life: 3000,
    });
  };

  const showWarn = (message) => {
    toast.current.show({
      severity: "warn",
      summary: "Warn Message",
      detail: message,
      life: 3000,
    });
  };

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error Message",
      detail: message,
      life: 3000,
    });
  };
  return {
    showSuccess,
    showInfo,
    showWarn,
    showError,
  };
};

export const ToastProvider = ({ children }) => {
  const toastRef = useRef(null);
  const toast = useToastProvider(toastRef);
  return (
    <toastContext.Provider value={toast}>
      <Toast ref={toastRef} position="top-center" />
      {children}
    </toastContext.Provider>
  );
};

export const useToast = () => useContext(toastContext);
