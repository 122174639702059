import React from 'react'
import { useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';

function ErrorPage() {
  const navigate = useNavigate();
  return (
    
    <div className="surface-0 h-full text-700 text-center flex justify-content-center flex-column">
        <div className="text-blue-600 font-bold mb-3">ATLANTE</div>
        <div className="text-900 font-bold text-5xl mb-3">This page doesn't exists</div>
        <div>
          <Button 
          onClick={() => navigate(`/`)}
          label="Back to login "
          className="p-button-text "
          icon="pi pi-reply"
          iconPos="right"
          /> 
          </div>
    </div> 
  )
}

export default ErrorPage