import axios from 'axios'

const AxiosClient = axios.create({
    baseURL: process.env.REACT_APP_ATLANTE_API_BASE_URL,
    // withCredentials: false,
    headers: {}
})

// Intercept response
AxiosClient.interceptors.response.use(
(response)=> {
    return Promise.resolve(response)
}, (error) => {
    if(error?.response?.status === 401) {
        // TODO Remove user from local storage
        // TODO Redirect to login
    }
    return Promise.reject(error);

})

export default AxiosClient