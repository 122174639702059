import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { DataTable } from "primereact/datatable";
import { Column } from 'primereact/column';
import getLightTheme from "src/services/LineChartTheme";

function LineChart({lineStylesData, graphPairs}) {
  const { basicOptions } = getLightTheme();

  return (
    <div>
        <Card>
          <Chart type="line" data={lineStylesData} options={basicOptions} />  
          <DataTable value={graphPairs} size="small" scrollable scrollHeight="400px">
            <Column field="xy" header="Coppia Ordinata" style={{ minWidth: '200px'}} align="center"></Column>
            <Column field="x" header="Asse delle ascisse" style={{ minWidth: '200px' }} align="center"></Column>
            <Column field="y" header="Asse delle ordinate" style={{ minWidth: '200px' }} align="center"></Column>
          </DataTable>
        </Card>
    </div>
  )
}

export default LineChart