import React, { useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useAuth } from "src/providers/auth.provider";
import { useToast } from "src/providers/toast.provider";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      // email: "",
      password: "",
      username: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.username) {
        errors.username = "Username è richiesto.";
      }
      if (!data.password) {
        errors.password = "La password è richiesta.";
      }
      return errors;
    },
    onSubmit: async (data) => {
      setIsLoading(true);
      try {
        await auth.login(data);
        navigate("/dashboard");
      } catch (e) {
        // TODO Update with error message that arrives from api
        toast.showError("Errore");
      } finally {
        setIsLoading(false);
        // formik.resetForm();
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <div className="flex h-full align-items-center justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4 md:w-6 sm:w-10 w-11">
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">Login</div>
        </div>
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          {/* Email */}
          <label
            htmlFor="username"
            className={classNames(
              { "p-error": isFormFieldValid("username") },
              "block text-900 font-medium mb-2"
            )}
          >
            Username
          </label>
          <InputText
            id="username"
            name="username"
            value={formik.values.username}
            onChange={formik.handleChange}
            type="text"
            className={classNames(
              { "p-invalid": isFormFieldValid("username") },
              "w-full"
            )}
          />
          {getFormErrorMessage("username")}
          {/* Password */}
          <label
            htmlFor="password"
            className={classNames(
              { "p-error": isFormFieldValid("password") },
              "block text-900 font-medium mb-2 mt-2"
            )}
          >
            Password
          </label>
          <InputText
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            type="password"
            className={classNames(
              { "p-invalid": isFormFieldValid("password") },
              "w-full"
            )}
          />
          {getFormErrorMessage("password")}
          <Button
            loading= {isLoading}
            type="submit"
            label="Log In"
            icon="pi pi-user"
            className="w-full mt-4"
          />
        </form>
      </div>
    </div>
  );
};

export default Login;
