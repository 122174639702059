import { Routes, Route } from "react-router-dom";
import PrivateRoute from "src/router/PrivateRoute";
// Pages
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import MeasurementDetails from "./pages/MeasurementDetails";
// Providers
import { AuthProvider } from "src/providers/auth.provider";
import { ToastProvider } from "src/providers/toast.provider";
//Styles
import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ErrorPage from "./pages/ErrorPage";

function App() {
  return (
    <ToastProvider>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<PrivateRoute />}>
            <Route index element={<Dashboard />} />
            <Route path=":measurementId" element={<MeasurementDetails />} />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </AuthProvider>
    </ToastProvider>
  );
}

export default App;