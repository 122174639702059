import AxiosClient from "src/api/AxiosClient";

//  * @description Destructured axios and got the data response from its response object
//  * @param response
//  */
const responseBody = (response) => response.data;

//  * @description  Created a request object to handle GET and returned the destructured axios body created on line 9
//  */
const ApiService = {
    get: (url, p) => {
        return AxiosClient.get(url, p).then(responseBody)
    },
    post(resource, data) {
        return AxiosClient.post(resource, data).then(responseBody)
    },

    put(resource, data) {
        return AxiosClient.put(resource, data).then(responseBody);
    },

    delete(resource) {
        return AxiosClient.delete(resource).then(responseBody);
    },

    customRequest(data) {
        return AxiosClient(data).then(responseBody);
    },
};

export default ApiService;