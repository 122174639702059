
const LocalStorageService = {
    getElement(key) {
        const element = window.localStorage.getItem(key);
        return JSON.parse(element);
    },
    setElement(key, value) {
        window.localStorage.setItem(key, value);
    },
    deleteElement(key) {
        window.localStorage.removeItem(key); 
    }
}

export default LocalStorageService;