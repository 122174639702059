import React from "react";

const Container = ({children}) => {
  return (
    <div className="flex h-full justify-content-center">
      <div className="md:p-6 mt-4 lg:p-8 sm:p-6 pt-8 px-4 pb-2 w-full">
        {children}
      </div>
    </div>
  );
};

export default Container;
